import {Row, Col, Container, Form, Button} from 'react-bootstrap';
import {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDumbbell, faUtensils, faWeightScale} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

axios.defaults.baseURL = 'https://api.karvedup.com';

function App() {
  const [formData, setFormData] = useState(() => {
    return {
      first_name: '',
      email_address: '',
      monthly_price_range: '',
      receive_offers: false
    };
  });
  const [isSubmitting, setIsSubmitting] = useState(() => false);
  const [formErrors, setFormErrors] = useState(() => []);
  const [formSuccess, setFormSuccess] = useState(() => false);


  const updateFormData = (key, value) => {
    setFormData({
      ...formData,
      [key]: value
    });
  }

  const submit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await axios.post('/api/register', {
        first_name: formData.first_name,
        email_address: formData.email_address,
        monthly_price_range: formData.monthly_price_range,
        receive_offers: formData.receive_offers
      });
      //TODO: Show Success Message
      setFormSuccess(true);
    } catch(err) {
      console.log("Error submitting form: ", err);
      //TODO: Show Error Message
      setFormErrors([err.response.data.message]);
      setIsSubmitting(false);
    }
    
  }

  const buildInformationSection = () => {
    return (
      <div className="karved-up-form-companion">
        <h3>The only Fitness App you'll ever need</h3>
        <Row>
        <img style={{maxHeight: 300}} className="img-fluid" src="/ketoshreds-min.png" alt="KarvedUp Phone"/>
        </Row>
        <hr/>
        <Row>
          <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2}>
            <FontAwesomeIcon icon={faDumbbell} size="3x" />
          </Col>

          <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
            <h5>Custom Workouts</h5>
            <p>Tailored to your fitness goals and preferences.</p>
          </Col>
        </Row>
        <hr/>
        <Row className="mt-3">
        <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} className="text-center">
            <FontAwesomeIcon icon={faUtensils} size="3x" />
          </Col>
          <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
            <h5>Macro + Calorie Tracking</h5>
            <p>Stay on top of your diet with ease.</p>
          </Col>
          
        </Row>
        <hr/>
        <Row className="mt-3">
          <Col xs={2} sm={2} md={2} lg={2} xl={2} xxl={2} className="text-center">
            <FontAwesomeIcon icon={faWeightScale} size="3x" />
          </Col>
          <Col xs={10} sm={10} md={10} lg={10} xl={10} xxl={10}>
            <h5>Progress Tracking + Automatic Adjustments</h5>
            <p>
            Track your journey and get smarter recommendations.
            </p>
          </Col>
        </Row>
      </div>
    );
  }

  const buildFormSection = () => {
    if (formSuccess) {
      return (
        <div className="karved-up-form-container">
          <h1>Thank you for signing up!</h1>
          <p>We will notify you when KarvedUp is available on the App Store.</p>
        </div>
      );
    }
    let errors = null;
    if (formErrors.length > 0) {
     errors = formErrors.map((error, index) => {
      return (
        <p key={index} className="text-danger">{error}</p>
      );
    });
  }

    return (
      <div className="karved-up-form-container">
            <h1>Get KarvedUp!</h1>
            <p>Be one of our first users for our initial launch!</p>
            <hr/>
            {errors}
            <Form onSubmit={submit}>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  isInvalid={formData.first_name.length < 1 ? true : false}
                  required
                  type="text"
                  value={formData.first_name}
                  onChange={(e) => updateFormData('first_name', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                 isInvalid={formData.email_address.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) ? false : true}
                 required
                  type="email"
                  value={formData.email_address}
                  onChange={(e) => updateFormData('email_address', e.target.value)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>What would you be willing to pay monthly?</Form.Label>
                <Form.Select 
                      value={formData.monthly_price_range}
                      isInvalid={formData.monthly_price_range.length < 1 ? true : false}
                      onChange={(e) => updateFormData('monthly_price_range', e.target.value)}
                      required>
                    <option value="">Select Monthly Price Range</option>
                    <option value={0}>$0-$4.99</option>
                    <option value={1}>$4.99-$9.99</option>
                    <option value={2}>$9.99-$14.99</option>
                    <option value={3}>$14.99-$19.99</option>
                    <option value={4}>$19.99+</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mt-3 mb-3">
                <Form.Check
                  type="checkbox"
                  label="I would like to receive additional offers and promotions from KarvedUp!"
                  checked={formData.receive_offers}
                  onChange={(e) => updateFormData('receive_offers', e.target.checked)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Button disabled={isSubmitting} type="submit" className="cta-button">GET NOTIFIED WHEN THE APP IS LIVE</Button>
                <p className="text-center mt-5">
                By submitting, you confirm that you are over 18 years of age and agree to our <a href="/terms-of-service" target="_blank"> Terms of Service</a> and <a href="/privacy-policy" target="_blank">Privacy Policy.</a> <br/><br/>
                You also agree to receive an email notification when KarvedUp becomes available on the App Store.
                </p>
              </Form.Group>
            </Form>
            </div>
    )

  }

  return (
    <div className="App">
      <Container>
        <Row className="mt-5" style={{paddingLeft:20, paddingRight: 20}}>
        <div style={{padding: '56.25% 0 0 0', position: 'relative'}}>
          <iframe src="https://player.vimeo.com/video/1007545885?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&autoplay=1&loop=1&title=0&byline=0&portrait=0" 
                  frameborder="0" 
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write" 
                  style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                  title="KarvedUp Landing Page Video"></iframe></div>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="mt-5 d-none d-sm-block">
            {buildFormSection()}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="mt-5 d-none d-sm-block">
            {buildInformationSection()}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="mt-5 d-block d-sm-none">
            {buildInformationSection()}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6} className="mt-5 d-block d-sm-none">
            {buildFormSection()}
          </Col>
          
        </Row>
      </Container>
    </div>
  );
}

export default App;
