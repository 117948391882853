import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.scss';
import Footer from './shared/Footer';
import Navbar from './shared/Navbar';
import TermsOfService from './pages/Terms-Of-Service';
import PrivacyPolicy from './pages/Privacy-Policy';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <div>
        <Navbar/>
        <Router>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
        </Router>
      
        <Footer />
    </div>
    
);

