
import {Container} from 'react-bootstrap';

export default function TermsOfService() {

return (
    <Container style={{backgroundColor: '#fff', color: '#000', padding: 40}}>
        <h1 className="mb-5">Terms of Service</h1>
        <p>Effective Date: September 7, 2024</p>

        <h2 className="mb-5">Welcome to KarvedUp!</h2>
        <p>By using our services, you agree to comply with and be bound by the following terms and conditions.</p>

        <h3 className="mb-5">1. Acceptance of Terms</h3>
        <p>By accessing or using the KarvedUp app, website, or services, you agree to these Terms of Service. If you do not agree with any part of these terms, please do not use our services.</p>

        <h3 className="mb-5">2. Eligibility</h3>
        <p>To use KarvedUp, you must be at least 18 years old. By registering or using our services, you confirm that you are 18 years of age or older.</p>

        <h3 className="mb-5">3. Accounts</h3>
        <p>You must provide accurate and complete information when creating an account.</p>

        <p>You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.</p>

        <p>KarvedUp reserves the right to suspend or terminate accounts that violate these terms.</p>

        <h3 className="mb-5">4. Subscription and Payments</h3>
        <p>KarvedUp offers a subscription service at $9.99 per month. Your subscription will renew automatically unless canceled prior to the renewal date.</p>

        <p>You may cancel your subscription at any time. Cancelations will take effect at the end of the current billing cycle.</p>
        
        <p>We reserve the right to adjust pricing at any time with proper notice to our users.</p>

        <h3 className="mb-5">5. Use of Service</h3>
        <p>You agree to use KarvedUp in compliance with all applicable laws and regulations. You agree not to:</p>

        <ul>
            <li>Violate any laws or regulations while using KarvedUp.</li>
            <li>Use KarvedUp for any fraudulent or malicious activities.</li>
            <li>Reproduce, distribute, or modify the content of KarvedUp without our written permission.</li>
        </ul>

        <h3 className="mb-5">6. Intellectual Property</h3>
        <p>All content provided by KarvedUp, including text, graphics, logos, and software, is the property of KarvedUp or its licensors. Unauthorized use of any materials is prohibited.</p>

        <h3 className="mb-5">7. Data and Privacy</h3>
        <p>KarvedUp’s collection and use of personal data are governed by our Privacy Policy. By using our services, you consent to the collection and use of your information as outlined in the Privacy Policy.</p>

        <h3 className="mb-5">8. Disclaimer</h3>
        <p>KarvedUp is provided on an “as is” and “as available” basis. We make no warranties, expressed or implied, regarding the app’s functionality, reliability, or fitness for a particular purpose.</p>

        <h3 className="mb-5">9. Limitation of Liability</h3>
        <p>KarvedUp will not be liable for any indirect, incidental, or consequential damages arising out of your use of our app or services.</p>

        <p>You acknowledge that KarvedUp is not designed to replace your Doctor or Physician. All health matters should be brought to your medical professional's attention.</p>

        <h3 className="mb-5">10. Amendments</h3>
        <p>We reserve the right to update or modify these Terms of Service at any time. We will notify users of significant changes. Continued use of KarvedUp following such changes constitutes acceptance of the updated terms.</p>

        <h3 className="mb-5">11. Governing Law</h3>
        <p>These Terms of Service are governed by the laws of California.</p>

        <h3 className="mb-5">12. Contact Information</h3>
        <p>For any questions about these Terms of Service, please contact us at
            <a href="mailto:rick@karvedup.com">Rick@KarvedUp.com</a>
        </p>


    </Container>
)
}